.FullTimeSettings {
  width: 1280px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .week {
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    .day {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      margin-right: 5px;
      caret-color: transparent;
    }

    .time {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      margin-right: 5px;
      caret-color: transparent;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
