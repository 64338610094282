@media only screen and (min-width : 1000px) {
  .CardDashedGraph {
    padding: 20px 40px 20px 60px;
    width: 100%;

    .header {
      display: flex;
      flex-direction: column;

      .title {
        .body1 {
          opacity: 0.6;
        }
      }

      .interface {
        display: flex;
        align-items: center;
        margin: 20px 0;

        .DatePicker {
          margin-right: 20px;

          .date-picker {
            background: #f0f1f3;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
          }
        }
        .datefromto {
          width: 480px;
        }

        .primary {
          width: 210px;

          svg {
            margin-left: 15px;
          }
        }

        .tabs {
          display: flex;
          align-items: center;
          margin-right: auto;

          .Catalog {
            margin-right: 10px;

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }

    .main-block {
      display: flex;

      .graph {
        flex: 1;
        width: 900px;
        height: 500px;
        margin-top: auto;
        margin-right: 80px;
      }

      .category-list {
        display: flex;
        flex-direction: column;

        .Category {
          width: 207px;
          margin-bottom: 20px;

          .Line {
            margin: 0 10px 0 0;
          }

          .type {
            color: #42454c;
            font-weight: 300;
          }
        }
      }
    }
  }
}

@media only screen and (max-width : 375px) {
  .CardDashedGraph {
    padding: 0px;
    margin-left: -40px;
    margin-top: -20px;
    width: 100%;
    border-radius: 2px;
  
    .header {
      display: flex;
      flex-direction: column;
  
      .title {
        width: 260px;
        .body1 {
          opacity: 0.6;
        }
      }
  
      .interface {
        display: flex;
        align-items: center;
        margin: 20px 0;
  
        .DatePicker {
          margin-right: 20px;
  
          .date-picker {
            background: #f0f1f3;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
          }
        }
        .datefromto {
          width: 260px;
        }
  
        .primary {
          width: 210px;
  
          svg {
            margin-left: 15px;
          }
        }
  
        .tabs {
          display: flex;
          align-items: center;
          margin-right: auto;
  
          .Catalog {
            margin-right: 10px;
  
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  
    .main-block {
      padding: 0;
      // display: flex;
      // flex-direction: column;
  
      .graph {
        flex: 1;
        width: 288px;
        height: 250px;
        margin-top: auto;
        margin-left: -7px;
        // margin-right: 80px;
        font-size: 12px;
        padding-right: 10px;
      }
  
      .category-list {
        display: flex;
        font-size: 12px;
        flex-direction: row;
        flex-wrap: wrap;
        background: #f0f1f3;
        padding: 10px;
        width: 240px;
        border-radius: 5px;
  
        .Category {
          width: 100px;
          margin-bottom: 10px;
          align-items: center;
          display: flex;
          flex-direction: row;
  
          .Line {
            display: flex;
            margin: 0 4px 0 0px;
            min-height: 20px;
          }
  
          .type {
            color: #42454c;
            font-weight: 300;
          }
        }
      }
    }
  }
}

@media only screen and (min-width : 375px) and (max-width: 768px) {
  .CardDashedGraph {
    padding: 0px;
    margin-left: -40px;
    margin-top: -20px;
    width: 100%;
    border-radius: 2px;
  
    .header {
      display: flex;
      flex-direction: column;
  
      .title {
        width: 343px;
        .body1 {
          opacity: 0.6;
        }
      }
  
      .interface {
        display: flex;
        align-items: center;
        margin: 20px 0;
  
        .DatePicker {
          margin-right: 20px;
  
          .date-picker {
            background: #f0f1f3;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
          }
        }
        .datefromto {
          width: 343px;
        }
  
        .primary {
          width: 210px;
  
          svg {
            margin-left: 15px;
          }
        }
  
        .tabs {
          display: flex;
          align-items: center;
          margin-right: auto;
  
          .Catalog {
            margin-right: 10px;
  
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  
    .main-block {
      padding: 0;
      // display: flex;
      // flex-direction: column;
  
      .graph {
        flex: 1;
        width: 343px;
        height: 250px;
        margin-top: auto;
        margin-left: -7px;
        // margin-right: 80px;
        font-size: 12px;
        padding-right: 10px;
      }
  
      .category-list {
        display: flex;
        font-size: 12px;
        flex-direction: row;
        flex-wrap: wrap;
        background: #f0f1f3;
        padding: 10px;
        width: 300px;
        border-radius: 5px;
  
        .Category {
          width: 140px;
          margin-bottom: 10px;
          align-items: center;
          display: flex;
          flex-direction: row;
  
          .Line {
            display: flex;
            margin: 0 4px 0 0px;
            min-height: 20px;
          }
  
          .type {
            color: #42454c;
            font-weight: 300;
          }
        }
      }
    }
  }
}

@media only screen and (min-width : 768px) and (max-width: 1000px) {
  .CardDashedGraph {
    padding: 0px;
    margin-left: -40px;
    margin-top: -20px;
    width: 100%;
    border-radius: 2px;
  
    .header {
      display: flex;
      flex-direction: column;
  
      .title {
        width: 704px;
        .body1 {
          opacity: 0.6;
        }
      }
  
      .interface {
        display: flex;
        align-items: center;
        margin: 20px 0;
  
        .DatePicker {
          margin-right: 20px;
  
          .date-picker {
            background: #f0f1f3;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
          }
        }
        .datefromto {
          width: 704px;
        }
  
        .primary {
          width: 210px;
  
          svg {
            margin-left: 15px;
          }
        }
  
        .tabs {
          display: flex;
          align-items: center;
          margin-right: auto;
  
          .Catalog {
            margin-right: 10px;
  
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  
    .main-block {
      padding: 0;
      // display: flex;
      // flex-direction: column;
  
      .graph {
        flex: 1;
        width: 704px;
        height: 350px;
        margin-top: auto;
        margin-left: -7px;
        // margin-right: 80px;
        font-size: 12px;
        padding-right: 10px;
      }
  
      .category-list {
        display: flex;
        font-size: 12px;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: 20px;
        // background: #f0f1f3;
        padding: 10px;
        width: 600px;
        border-radius: 5px;
  
        .Category {
          width: 140px;
          margin-bottom: 10px;
          align-items: center;
          display: flex;
          flex-direction: row;
          gap: 5px;
  
          .Line {
            display: flex;
            margin: 0 4px 0 0px;
            min-height: 20px;
          }
  
          .type {
            color: #42454c;
            font-weight: 300;
          }
        }
      }
    }
  }
}