@media only screen and (min-width : 1000px) {
  .Header {
    background: #f7f7f7;
    border-bottom: 1px solid rgba(#fff, 0.1);
    width: 100%;
    align-items: center;

    .header-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 60px;
      padding: 0 30px;
      margin: 0 auto;
      width: 1440px;

      .profile {
        display: flex;
        align-items: center;

        .wrapp {
          display: flex;
          align-items: center;
          border-right: 1px solid rgba(66, 69, 76, 0.1);
          padding-right: 25px;
          height: 25px;

          .balance {
            font-weight: 500;
            font-size: 16px;
            margin-right: 10px;
          }

          .sum {
            font-weight: bold;
            font-size: 16px;
            color: #3f73f9;
          }
        }

        .exit {
          margin-left: 25px;
        }
      }
    }
  }
}

@media only screen and (max-width : 374px) {
  .Header {
    // background: #f7f7f7;
    background: #F0F1F3;
    border-bottom: 1px solid rgba(#fff, 0.1);
    width: 100vw;
    display: flex;
    align-items: center;
    height: 46px;

    .header-container {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      height: 46px;
      margin-top: -7px;
      // width: 100%;
      // padding-bottom: 25px;
      // margin: 0 auto;
      max-width : 375px;

      .icon {
        display: none;
      }

      .profile {
        display: flex;
        flex-direction: row;
        gap: 7px;
        // justify-content: space-between;
        align-items: center;
        // padding: 16px;

        .wrapp {
          display: flex;
          align-items: center;
          border-right: 1px solid rgba(66, 69, 76, 0.1);
          padding-right: 38px;
          // height: 25px;

          .balance {
            font-weight: 700;
            font-size: 14px;
            margin-right: 16px;
          }

          .sum {
            font-weight: bold;
            font-size: 16px;
            color: #3f73f9;
          }
        }

        button {
          width: 20px;
          margin-left: 10px;
        }
      }
    }
  }
}

@media only screen and (min-width : 375px) and (max-width: 767px) {
  .Header {
    // background: #f7f7f7;
    background: #F0F1F3;
    border-bottom: 1px solid rgba(#fff, 0.1);
    width: 100vw;
    display: flex;
    align-items: center;
    height: 54px;

    .header-container {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      height: 54px;
      margin-top: -7px;
      // width: 343px;
      // padding-bottom: 25px;
      // margin: 0 auto;
      max-width : 343px;

      .icon {
        display: none;
      }

      .profile {
        display: flex;
        flex-direction: row;
        gap: 20px;
        justify-content: space-between;
        align-items: center;
        padding-left: 16px;

        .wrapp {
          display: flex;
          align-items: center;
          border-right: 1px solid rgba(66, 69, 76, 0.1);
          padding-right: 38px;
          // height: 25px;

          .balance {
            font-weight: 700;
            font-size: 14px;
            margin-right: 16px;
          }

          .sum {
            font-weight: bold;
            font-size: 16px;
            color: #3f73f9;
          }
        }

        button {
          width: 20px;
          margin-left: 10px;
        }
      }
    }
  }
}

@media only screen and (min-width : 767px) and (max-width: 1000px) {
  .Header {
    background: #F0F1F3;
    border-bottom: 1px solid rgba(#fff, 0.1);
    width: 100%;
    align-items: center;

    .header-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      height: 60px;
      padding: 0 30px;
      margin: 0 auto;
      width: 768px;

      .profile {
        display: flex;
        align-items: center;

        .wrapp {
          display: flex;
          align-items: center;
          border-right: 1px solid rgba(66, 69, 76, 0.1);
          padding-right: 25px;
          height: 25px;

          .balance {
            font-weight: 500;
            font-size: 16px;
            margin-right: 10px;
          }

          .sum {
            font-weight: bold;
            font-size: 16px;
            color: #3f73f9;
          }
        }

        .exit {
          margin-left: 25px;
        }
      }
    }
  }
}