.YaMap {
  .map {
    .ymaps-2-1-79-map {
      .ymaps-2-1-79-inner-panes {
        .ymaps-2-1-79-copyrights-pane {
          display: none;
        }
      }
    }
  }
}
