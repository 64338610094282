.HaveBusiness {
  .have-busines {
    background: #f0f1f3;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
      0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 8px 20px;

    .arrow-icon {
      margin-left: 15px;

      &__open {
        transform: rotate(180deg);
        margin-left: 15px;
      }
    }
  }
}

.Modal {
  .wrapper {
    width: 670px;

    .content {
      .have-busines__visible {
        .have-busines__field {
          position: relative;

          input {
            width: 100%;
            padding: 8px 40px 8px 10px;
            background: #ffffff;
            border: 1px solid rgba(66, 69, 76, 0.2);
            box-sizing: border-box;
            border-radius: 4px;
            margin: 0;
          }

          .search-icon {
            position: absolute;
            top: 7px;
            right: 20px;
          }

          .toggle-icon {
            position: absolute;
            right: 14px;
            top: 14px;

            &__open {
              transform: rotate(180deg);
              position: absolute;
              right: 14px;
              top: 14px;
            }
          }

          &:first-child {
            margin-bottom: 20px;
          }
        }

        .have-busines__hidden {
          background: #ffffff;
          box-shadow: 0px 0.6px 1.8px rgba(0, 0, 0, 0.11),
            0px 3.2px 7.2px rgba(0, 0, 0, 0.13);
          padding: 10px;
          margin-top: 5px;

          .Checkbox {
            margin-bottom: 20px;

            &:first-child {
              padding-bottom: 10px;
              margin-top: 0;
              border-bottom: 1px solid #c7c8ce;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }

        .btn-actions {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-top: 20px;

          .Button {
            max-width: 210px;
          }

          .secondary {
            width: 210px;
          }

          .primary {
            margin-left: 20px;
          }
        }
      }
    }
  }
}
