.Modal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;

  button.close {
    margin-left: 10px;
  }

  .wrapper {
    background: #f0f1f3;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
      0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    min-width: 310px;
    min-height: 180px;
    padding: 40px;
    display: flex;
    flex-direction: column;
  }

  .title {
    color: #42454c;
    font-size: 24px;
    margin-bottom: 40px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
  }

  .content {
    display: flex;
    flex-direction: column;
  }
}
