.Retargeting {
  width: 100%;

  .retargeting__field {
    position: relative;

    input {
      width: 100%;
      padding: 8px 40px 8px 10px;
      background: #f0f1f3;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04),
        0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
      border-radius: 4px;
      margin: 0;
    }

    .toggle-icon {
      position: absolute;
      right: 14px;
      top: 14px;

      &__open {
        transform: rotate(180deg);
        position: absolute;
        right: 14px;
        top: 14px;
      }
    }
  }
}

.Modal {
  .wrapper {
    width: 670px;

    .content {
      .retargeting__visible {
        position: relative;

        .retargeting-field {
          background: #ffffff;
          border: 1px solid rgba(66, 69, 76, 0.2);
          border-radius: 4px;
          width: 100%;
          margin-bottom: 0;
        }

        .arrow-icon {
          position: absolute;
          right: 14px;
          top: 14px;

          &__open {
            transform: rotate(180deg);
            position: absolute;
            right: 14px;
            top: 14px;
          }
        }
      }

      .retargeting__hidden {
        background: #ffffff;
        border: 1px solid rgba(66, 69, 76, 0.2);
        padding: 10px;
        margin-top: 5px;

        .Checkbox {
          margin-bottom: 20px;

          &:first-child {
            padding-bottom: 10px;
            margin-top: 0;
            border-bottom: 1px solid #c7c8ce;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .btn-actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 20px;

        .Button {
          min-width: 210px;
        }

        .primary {
          margin-left: 20px;
          margin-bottom: 0;
        }
      }
    }
  }
}
