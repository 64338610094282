@media screen and (max-width : 375px) {
    .modalSelect {
        .css-10ghyrq-MuiFormControl-root {
            width: 195px !important;
        }
    }

    .audience {
        margin-left: 16px !important;
        margin-right: 16px;
        width: 288px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .css-1h2p78b-MuiGrid-root {
            margin-top: 0 !important;
        }

        .title-text {
            margin-bottom: 20px !important;
            margin-top: 40px !important;
            color: rgba(66, 69, 76, 0.60);

            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            width: 287px;

            h2 {
                color: var(--colors-42454-c, #42454C);

                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
        }

        .addButton {
            width: 287px;
            height: 30px;
            border-radius: 4px;
            background: var(--colors-gradient-2, linear-gradient(45deg, #6B58E0 0%, #3F73F9 100%));
            color: #FFF !important;
            text-align: center !important;

            font-size: 12px !important;
            font-style: normal !important;
            font-weight: 400 !important;
            line-height: normal !important;
            white-space: nowrap;
        }

        .createButton {
            text-decoration: underline !important;

            font-size: 12px !important;
            font-style: normal !important;
            font-weight: 400 !important;
            line-height: normal !important;
        }

        .css-1869usk-MuiFormControl-root {
            margin: 0 !important;
            margin-bottom: 13px !important;
        }

        .css-y62u9e-MuiFormControl-root-MuiTextField-root {
            width: 287px !important;
            height: 27px;
        }

        .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
            top: -11px !important;
            color: rgba(66, 69, 76, 0.50) !important;

            font-size: 14px !important;
            font-style: normal !important;
            font-weight: 400 !important;
            line-height: normal !important;
        }

        .css-p0rm37 {
            top: -11px !important;
            color: rgba(66, 69, 76, 0.50) !important;
            font-size: 14px !important;
            font-style: normal !important;
            font-weight: 400 !important;
            line-height: normal !important;
        }

        .css-p0rm37.Mui-focused {
            color: rgba(66, 69, 76, 0.50) !important;
            top: -7px !important;
        }

        .css-1kty9di-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
            color: rgba(66, 69, 76, 0.50) !important;
            ;
            top: -7px !important;
        }

        .Mui-error {
            color: red !important;
        }

        .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
            height: 27px;
            width: 287px;
            border-radius: 4px;
            background: #F0F1F3;
            box-shadow: 0px 0px 0.8372092843055725px 0px rgba(0, 0, 0, 0.04), 0px 0px 1.674418568611145px 0px rgba(0, 0, 0, 0.06), 0px 3.34883713722229px 6.69767427444458px 0px rgba(0, 0, 0, 0.04);

            input {
                font-size: 14px;
                padding: 0;
                padding-left: 10px;
                box-shadow: none;
            }

            fieldset {
                border: none;
            }
        }

        .css-1v4ccyo {
            height: 27px;
            width: 287px;
            border-radius: 4px;
            background: #F0F1F3;
            box-shadow: 0px 0px 0.8372092843055725px 0px rgba(0, 0, 0, 0.04), 0px 0px 1.674418568611145px 0px rgba(0, 0, 0, 0.06), 0px 3.34883713722229px 6.69767427444458px 0px rgba(0, 0, 0, 0.04);

            input {
                font-size: 14px;
                padding: 0;
                padding-left: 10px;
                box-shadow: none;
            }

            fieldset {
                border: none;
            }
        }

        .dropzone1 {
            color: #3f73f9;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 8px !important;
            padding: 20px;
            cursor: pointer;
            height: 130px;
            width: 287px;
            background: #eff4ff;
            border: 1px dashed #3f73f9;
            outline: none;
            border-radius: 5px;
        }

        .dropzoneText {
            padding-top: 5px;
            height: 27px;
            color: rgba(66, 69, 76, 0.50);

            font-size: 14px !important;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            padding-left: 10px;
            text-align: start !important;
            border-radius: 4px;
            background: #F0F1F3;
            box-shadow: 0px 0px 0.8372092843055725px 0px rgba(0, 0, 0, 0.04), 0px 0px 1.674418568611145px 0px rgba(0, 0, 0, 0.06), 0px 3.34883713722229px 6.69767427444458px 0px rgba(0, 0, 0, 0.04);
        }

        .media-plan__drag-text {
            font-size: 10px;
            line-height: 9px;
            color: #3f73f9;
        }

        .title {
            margin: 0 !important;
            color: var(--colors-42454-c, #42454C) !important;

            font-size: 13.434px !important;
            font-style: normal !important;
            font-weight: 700 !important;
            line-height: normal !important;
            text-transform: capitalize !important;
        }
    }
}

@media screen and (min-width:375px) and (max-width : 768px) {
    .css-13xfq8m-MuiTabPanel-root:has(.audience) {
        margin: 0 !important;
    }
    .css-19kzrtu:has(.audience) {
        padding: 24px 24px 24px 0px;
        margin: 0 !important;
    }

    .css-13xfq8m-MuiTabPanel-root:has(.audience) {
        padding: 24px 24px 24px 0px;
        margin: 0 !important;
    }

    .modalSelect {
        .css-10ghyrq-MuiFormControl-root {
            width: 195px !important;
        }
    }

    .audience {
        margin-left: 0 !important;
        margin-right: 8px;
        width: 343px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .Typography {
            &.blue {
                color: #3f73f9;
            }
        }

        .Card .Typography {
            opacity: 1;
            font-size: 12px;
        }

        .css-1h2p78b-MuiGrid-root {
            margin-top: 0 !important;
        }

        .title-text {
            margin-bottom: 20px !important;
            margin-top: 0px !important;
            color: rgba(66, 69, 76, 0.60);

            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            width: 342px;

            h2 {
                color: var(--colors-42454-c, #42454C);

                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
        }

        .addButton {
            width: 342px;
            height: 32px;
            border-radius: 4px;
            background: var(--colors-gradient-2, linear-gradient(45deg, #6B58E0 0%, #3F73F9 100%));
            color: #FFF !important;
            text-align: center !important;

            font-size: 12px !important;
            font-style: normal !important;
            font-weight: 400 !important;
            line-height: normal !important;
            white-space: nowrap;
        }

        .createButton {
            text-decoration: underline !important;

            font-size: 12px !important;
            font-style: normal !important;
            font-weight: 400 !important;
            line-height: normal !important;
        }

        .css-1869usk-MuiFormControl-root {
            margin: 0 !important;
            margin-bottom: 13px !important;
        }

        .css-y62u9e-MuiFormControl-root-MuiTextField-root {
            width: 342px !important;
            height: 32px;
        }

        .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
            top: -11px !important;
            color: rgba(66, 69, 76, 0.50) !important;

            font-size: 14px !important;
            font-style: normal !important;
            font-weight: 400 !important;
            line-height: normal !important;
        }

        .css-p0rm37 {
            top: -11px !important;
            color: rgba(66, 69, 76, 0.50) !important;
            font-size: 14px !important;
            font-style: normal !important;
            font-weight: 400 !important;
            line-height: normal !important;
        }

        .css-p0rm37.Mui-focused {
            color: rgba(66, 69, 76, 0.50) !important;
            top: -7px !important;
        }

        .css-1kty9di-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
            color: rgba(66, 69, 76, 0.50) !important;
            ;
            top: -7px !important;
        }

        .Mui-error {
            color: red !important;
        }

        .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
            height: 32px;
            width: 342px;
            border-radius: 4px;
            background: #F0F1F3;
            box-shadow: 0px 0px 0.8372092843055725px 0px rgba(0, 0, 0, 0.04), 0px 0px 1.674418568611145px 0px rgba(0, 0, 0, 0.06), 0px 3.34883713722229px 6.69767427444458px 0px rgba(0, 0, 0, 0.04);

            input {
                font-size: 14px;
                padding: 0;
                padding-left: 10px;
                box-shadow: none;
            }

            fieldset {
                border: none;
            }
        }

        .css-1v4ccyo {
            height: 32px;
            width: 342px;
            border-radius: 4px;
            background: #F0F1F3;
            box-shadow: 0px 0px 0.8372092843055725px 0px rgba(0, 0, 0, 0.04), 0px 0px 1.674418568611145px 0px rgba(0, 0, 0, 0.06), 0px 3.34883713722229px 6.69767427444458px 0px rgba(0, 0, 0, 0.04);

            input {
                font-size: 14px;
                padding: 0;
                padding-left: 10px;
                box-shadow: none;
            }

            fieldset {
                border: none;
            }
        }

        .dropzone1 {
            color: #3f73f9;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 8px !important;
            padding: 20px;
            cursor: pointer;
            height: 80px;
            width: 342px;
            background: #eff4ff;
            border: 1px dashed #3f73f9;
            outline: none;
            border-radius: 5px;
        }

        .dropzoneText {
            padding-top: 5px;
            height: 32px;
            color: rgba(66, 69, 76, 0.50);

            font-size: 14px !important;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            padding-left: 10px;
            text-align: start !important;
            border-radius: 4px;
            background: #F0F1F3;
            box-shadow: 0px 0px 0.8372092843055725px 0px rgba(0, 0, 0, 0.04), 0px 0px 1.674418568611145px 0px rgba(0, 0, 0, 0.06), 0px 3.34883713722229px 6.69767427444458px 0px rgba(0, 0, 0, 0.04);
        }

        .media-plan__drag-text {
            font-size: 10px;
            line-height: 9px;
            color: #3f73f9;
        }

        .title {
            margin: 0 !important;
            color: var(--colors-42454-c, #42454C) !important;

            font-size: 13.434px !important;
            font-style: normal !important;
            font-weight: 700 !important;
            line-height: normal !important;
            text-transform: capitalize !important;
        }
    }
}

@media screen and (min-width:768px) and (max-width : 1000px) {
    .css-13xfq8m-MuiTabPanel-root:has(.audience) {
        padding: 24px 24px 24px 4px;
        margin: 0 !important;
    }
    .audience {

        .addButton {
            width: 600px;
            height: 42px;
            border-radius: 4px;
            background: var(--colors-gradient-2, linear-gradient(45deg, #6B58E0 0%, #3F73F9 100%));
            color: #FFF !important;
            text-align: center !important;
            font-size: 12px !important;
            font-style: normal !important;
            font-weight: 400 !important;
            line-height: normal !important;
            white-space: nowrap;
        }

        .createButton {
            text-decoration: underline;
        }
    }
}