.form-with-text {
  display: flex;
  flex-direction: column;
  gap: 10px;

  input {
    width: 100%
  }

  .success-text {
    background: green;
    color: white;
    padding: 10px;
    border-radius: 5px;
  }
}