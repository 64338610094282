@media only screen and (min-width : 1000px) {
  .User {
    display: flex;
    align-items: center;
    position: relative;
    margin: 0 0 0 25px; // < - временно пока скрыта икнока уведомлений

    .info {
      text-align: right;
      margin-right: 20px; // <-  временно пока скрыт аватар

      .body2 {
        opacity: 0.6;
        font-weight: 400;
      }
    }

    .avatar {
      margin: 0 15px;
    }

    .drop {
      display: flex;
      align-items: center;
    }

    .Dropdown-content {
      .body2 {
        padding: 12px 20px;

        &:hover {
          background: rgba(163, 148, 142, 0.05);
        }
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .User {
    display: flex;
    align-items: center;
    position: relative;
    margin: 0 0 0 25px; // < - временно пока скрыта икнока уведомлений

    .info {
      text-align: right;
      margin-right: 20px; // <-  временно пока скрыт аватар

      .body2 {
        opacity: 0.6;
        font-weight: 400;
      }
    }

    .avatar {
      margin: 0 15px;
    }

    .drop {
      display: flex;
      align-items: center;
    }

    .Dropdown-content {
      .body2 {
        padding: 12px 20px;

        &:hover {
          background: rgba(163, 148, 142, 0.05);
        }
      }
    }
  }
}
