.Card {
  position: relative;
  background: linear-gradient(180deg, #fff 0%, #fff 100%) padding-box,
  linear-gradient(180deg, #6b58e0 0%, #3f73f9 100%) border-box;
  border-radius: 24px;
  border: 4px solid transparent;
  // overflow: hidden;
  .content {
    padding: 40px;
  }

  .toggle {
    position: absolute;
    bottom: -4px;
    left: 50%;
    transform: translateX(-50%);
    width: 315px;
    height: 15px;

    .rotated {
      transform: rotate(180deg);
    }
  }
}

.BLOCKED {
  background: linear-gradient(180deg, #fff 0%, #fff 100%) padding-box,
  linear-gradient(180deg, #e05858 0%, #F93766 100%) border-box;
}

.NOTCOMPLETE {
  background: linear-gradient(180deg, #fff 0%, #fff 100%) padding-box,
  linear-gradient(180deg, #42454c 0%, #95989D 100%) border-box;
}

.MODERATING {
  background: linear-gradient(180deg, #fff 0%, #fff 100%) padding-box,
  linear-gradient(180deg, #fc6c38 0%, #f37318 100%) border-box;
}