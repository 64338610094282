@media only screen and (min-width : 1000px) {
  .header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 200px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
    margin: 0 auto;
    width: 1440px;

    .oferta {
      display: flex;
      flex-direction:column;
      align-items: flex-end;
    }
  }
} 

@media only screen and (max-width : 1000px) {
  .header-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: 30px;
    padding-top: 30px;
    margin: auto auto;

    .oferta {
      display: flex;
      flex-direction: row;
      gap: 5px;
      align-items: flex-end;
    }
  }
}