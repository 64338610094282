@media screen and (max-width : 375px) {
    .optimization__wrapper {
        .title-text {
            margin-bottom: 40px !important;
            margin-top: 40px !important;
            color: rgba(66, 69, 76, 0.60);
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            width: 287px;

            h2 {
                color: var(--colors-42454-c, #42454C);
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
        }

        .title {
            margin: 0 !important;
            color: var(--colors-42454-c, #42454C) !important;
            font-size: 13.434px !important;
            font-style: normal !important;
            font-weight: 700 !important;
            line-height: normal !important;
            text-transform: capitalize !important;
            margin-bottom: 9px !important;
        }

        .radioGroup {
            color: black;
            flex-direction: column;
            margin-left: 0 !important;
            display: flex;
            margin-bottom: 20px !important;
        }

        .css-ahj2mt-MuiTypography-root {
            color: var(--colors-42454-c, #42454C) !important;
            font-size: 12px !important;
            font-style: normal !important;
            font-weight: 400 !important;
            line-height: normal !important;
        }

        .css-tmctnw-MuiGrid-root>.MuiGrid-item {
            padding-top: 0 !important;
        }

        .tr {
            position: relative;
            top: 20px;
        }

        .optimItem2 {
            padding-bottom: 20px !important;
        }

        .css-y62u9e-MuiFormControl-root-MuiTextField-root {
            width: 287px !important;
            height: 27px;
        }

        .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
            top: -11px !important;
            color: rgba(66, 69, 76, 0.50) !important;
            font-size: 14px !important;
            font-style: normal !important;
            font-weight: 400 !important;
            line-height: normal !important;
        }

        .css-1kty9di-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
            color: rgba(66, 69, 76, 0.50) !important;
            ;
            top: -7px !important;
        }

        .Mui-error {
            color: red !important;
        }

        .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
            height: 27px;
            width: 287px;
            border-radius: 4px;
            background: #F0F1F3;
            box-shadow: 0px 0px 0.8372092843055725px 0px rgba(0, 0, 0, 0.04), 0px 0px 1.674418568611145px 0px rgba(0, 0, 0, 0.06), 0px 3.34883713722229px 6.69767427444458px 0px rgba(0, 0, 0, 0.04);

            input {
                font-size: 14px;
                padding: 0;
                padding-left: 10px;
                box-shadow: none;
            }

            fieldset {
                border: none;
            }
        }

        .css-1v4ccyo {
            height: 27px;
            width: 287px;
            border-radius: 4px;
            background: #F0F1F3;
            box-shadow: 0px 0px 0.8372092843055725px 0px rgba(0, 0, 0, 0.04), 0px 0px 1.674418568611145px 0px rgba(0, 0, 0, 0.06), 0px 3.34883713722229px 6.69767427444458px 0px rgba(0, 0, 0, 0.04);

            input {
                font-size: 14px;
                padding: 0;
                padding-left: 10px;
                box-shadow: none;
            }

            fieldset {
                border: none;
            }
        }

        .cancel {
            position: absolute;
        }

        .br {
            display: none;
        }
    }
}

@media screen and (min-width:375px) and (max-width : 768px) {

    .optimization__wrapper {
        width: 343px;

        .title-text {
            margin-bottom: 40px !important;
            margin-top: 40px !important;
            color: rgba(66, 69, 76, 0.60);
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            width: 342px;

            h2 {
                color: var(--colors-42454-c, #42454C);
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
        }

        .MuiGrid-grid-xs-8 {
            width: 342px !important;
        }

        .title {
            margin: 0 !important;
            color: var(--colors-42454-c, #42454C) !important;
            font-size: 16px !important;
            font-style: normal !important;
            font-weight: 700 !important;
            line-height: normal !important;
            text-transform: capitalize !important;
            margin-bottom: 9px !important;
        }

        .radioGroup {
            color: black;
            flex-direction: column;
            margin-left: 0 !important;
            display: flex;
            margin-bottom: 20px !important;
            white-space: nowrap;
        }

        .css-ahj2mt-MuiTypography-root {
            color: var(--colors-42454-c, #42454C) !important;
            font-size: 12px !important;
            font-style: normal !important;
            font-weight: 400 !important;
            line-height: normal !important;
        }

        .css-tmctnw-MuiGrid-root>.MuiGrid-item {
            padding-top: 0 !important;
        }

        .tr {
            position: relative;
            top: 20px;
            white-space: nowrap;
        }

        .optimItem2 {
            padding-bottom: 20px !important;
        }

        .css-y62u9e-MuiFormControl-root-MuiTextField-root {
            width: 342px !important;
            height: 32px;
        }

        .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
            top: -11px !important;
            color: rgba(66, 69, 76, 0.50) !important;
            font-size: 14px !important;
            font-style: normal !important;
            font-weight: 400 !important;
            line-height: normal !important;
        }

        .css-1kty9di-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
            color: rgba(66, 69, 76, 0.50) !important;
            ;
            top: -7px !important;
        }

        .Mui-error {
            color: red !important;
        }

        .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
            height: 32px;
            width: 342px;
            border-radius: 4px;
            background: #F0F1F3;
            box-shadow: 0px 0px 0.8372092843055725px 0px rgba(0, 0, 0, 0.04), 0px 0px 1.674418568611145px 0px rgba(0, 0, 0, 0.06), 0px 3.34883713722229px 6.69767427444458px 0px rgba(0, 0, 0, 0.04);

            input {
                font-size: 14px;
                padding: 0;
                padding-left: 10px;
                box-shadow: none;
            }

            fieldset {
                border: none;
            }
        }

        .css-1v4ccyo {
            height: 32px;
            width: 342px;
            border-radius: 4px;
            background: #F0F1F3;
            box-shadow: 0px 0px 0.8372092843055725px 0px rgba(0, 0, 0, 0.04), 0px 0px 1.674418568611145px 0px rgba(0, 0, 0, 0.06), 0px 3.34883713722229px 6.69767427444458px 0px rgba(0, 0, 0, 0.04);

            input {
                font-size: 14px;
                padding: 0;
                padding-left: 10px;
                box-shadow: none;
            }

            fieldset {
                border: none;
            }
        }

        .cancel {
            position: absolute;
        }

        .br {
            display: none;
        }
    }
}

@media screen and (min-width:768px) and (max-width : 1000px) {

    .optimization__wrapper {
        margin-left: 0 !important;
        width: 656px;


        .title {
            margin: 0 !important;
            color: var(--colors-42454-c, #42454C) !important;
            font-size: 16px !important;
            font-style: normal !important;
            font-weight: 700 !important;
            line-height: normal !important;
            text-transform: capitalize !important;
            margin-bottom: 9px !important;
        }

        .radioGroup {
            color: black;
            flex-direction: column;
            margin-left: 0 !important;
            display: flex;
            margin-bottom: 20px !important;
            white-space: nowrap;
        }

        .css-ahj2mt-MuiTypography-root {
            color: var(--colors-42454-c, #42454C) !important;
            font-size: 14px !important;
            font-style: normal !important;
            font-weight: 400 !important;
            line-height: normal !important;
        }

        .css-tmctnw-MuiGrid-root>.MuiGrid-item {
            padding-top: 0 !important;
        }

        .tr {
            position: relative;
            top: 20px;
        }

        .optimItem2 {
            padding-bottom: 20px !important;
        }

        .css-y62u9e-MuiFormControl-root-MuiTextField-root {
            width: 342px !important;
            height: 32px;
        }

        .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
            top: -11px !important;
            color: rgba(66, 69, 76, 0.50) !important;
            font-size: 14px !important;
            font-style: normal !important;
            font-weight: 400 !important;
            line-height: normal !important;
        }

        .css-1kty9di-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
            color: rgba(66, 69, 76, 0.50) !important;
            ;
            top: -7px !important;
        }

        .Mui-error {
            color: red !important;
        }

        .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
            height: 32px;
            width: 342px;
            border-radius: 4px;
            background: #F0F1F3;
            box-shadow: 0px 0px 0.8372092843055725px 0px rgba(0, 0, 0, 0.04), 0px 0px 1.674418568611145px 0px rgba(0, 0, 0, 0.06), 0px 3.34883713722229px 6.69767427444458px 0px rgba(0, 0, 0, 0.04);

            input {
                font-size: 14px;
                padding: 0;
                padding-left: 10px;
                box-shadow: none;
            }

            fieldset {
                border: none;
            }
        }

        .css-1v4ccyo {
            height: 32px;
            width: 342px;
            border-radius: 4px;
            background: #F0F1F3;
            box-shadow: 0px 0px 0.8372092843055725px 0px rgba(0, 0, 0, 0.04), 0px 0px 1.674418568611145px 0px rgba(0, 0, 0, 0.06), 0px 3.34883713722229px 6.69767427444458px 0px rgba(0, 0, 0, 0.04);

            input {
                font-size: 14px;
                padding: 0;
                padding-left: 10px;
                box-shadow: none;
            }

            fieldset {
                border: none;
            }
        }

        .cancel {
            position: absolute;
        }

        .br {
            display: none;
        }
    }
}