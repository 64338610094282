@media screen and (max-width: 768px) {
    .title-text {
        margin-bottom: 40px !important;
        // margin-top: 40px !important;
        color: rgba(66, 69, 76, 0.60);
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 343px;

        h2 {
        color: var(--colors-42454-c, #42454C);
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        }
    }
}