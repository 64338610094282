.media-plan__wrapper {
  margin-left: 20px;
  background: transparent;


  .media-plan__drag-container {
    height: 80px;
    display: flex;
    gap: 5px;
    flex-direction: column;
  }

  .media-plan__drag-text {
    font-size: 10px;
    line-height: 12px;
    color: #3f73f9;
  }

  .media-plan__drag-container2 {
    width: 159px;
    display: flex;
    flex-direction: column;
  }

  .media-plan__drag-text2 {
    font-size: 10px;
    text-align: center;
    line-height: 14px;
    color: #3f73f9;
  }

  .media-plan {
    &__time-text {
      position: absolute;
      margin-top: 3px;
      margin-right: 14px;
      margin-left: 14px;
    }

    &__period {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .MuiOutlinedInput-input.focus-visible {
    box-shadow: none;
  }

}



@media screen and (min-width:375px) and (max-width : 767px) {
  .css-13xfq8m-MuiTabPanel-root:has(.media-plan__wrapper) {
    padding: 24px 24px 24px 16px;
  }

  .css-19kzrtu:has(.media-plan__wrapper) {
    padding: 24px 24px 24px 16px;
  }

  .media-plan__wrapper {
    width: 375px;
    padding: 0 10px;

    .css-u7lqxp {
      max-width: 100%;
    }

    .css-apkn2n-MuiGrid-root {
      max-width: 100%;
    }

    .css-u7lqxp {
      width: 343px;
    }

    .media-plan__drag-text {
      font-size: 10px;
      line-height: 9px;
      color: #3f73f9;
    }


    .days-grid {
      align-items: baseline;
      width: 343px;
      overflow-x: scroll;
    }

    .controls {
      gap: 12px !important;
      justify-content: flex-start;
      width: 336px;

      .controls_item {
        width: 336px;

        .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
          top: -7px !important;
          color: rgba(66, 69, 76, 0.50) !important;
          font-size: 14px !important;
          font-style: normal !important;
          font-weight: 400 !important;
          line-height: normal !important;
        }

        .css-p0rm37 {
          top: -7px !important;
          color: rgba(66, 69, 76, 0.50) !important;
          font-size: 14px !important;
          font-style: normal !important;
          font-weight: 400 !important;
          line-height: normal !important;
        }

        .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
          height: 32px;
          width: 336px;
          border-radius: 4px;
          background: #F0F1F3;
          box-shadow: 0px 0px 0.8372092843055725px 0px rgba(0, 0, 0, 0.04), 0px 0px 1.674418568611145px 0px rgba(0, 0, 0, 0.06), 0px 3.34883713722229px 6.69767432px444458px 0px rgba(0, 0, 0, 0.04);

          input {
            font-size: 14px;
            padding: 0;
            padding-left: 10px;
            box-shadow: none;
          }

          fieldset {
            border: none;
          }
        }

        .css-1v4ccyo {
          height: 32px;
          width: 336px;
          border-radius: 4px;
          background: #F0F1F3;
          box-shadow: 0px 0px 0.8372092843055725px 0px rgba(0, 0, 0, 0.04), 0px 0px 1.674418568611145px 0px rgba(0, 0, 0, 0.06), 0px 3.34883713722229px 6.69767432px444458px 0px rgba(0, 0, 0, 0.04);

          input {
            font-size: 14px;
            padding: 0;
            padding-left: 10px;
            box-shadow: none;
          }

          fieldset {
            border: none;
          }
        }

        &.timeTo {
          width: 162px;
        }

        &.timeFrom {
          width: 162px;
        }

        &.dateTo {
          width: 162px;
        }

        &.dateFrom {
          width: 162px;
        }
      }
    }

    .css-qazz20-MuiGrid-root {
      max-width: 50%;
    }

    .css-ws6o0d {
      max-width: 50%;
    }

    .css-16oxsim-MuiGrid-root {
      .MuiGrid-grid-xs-4 {
        max-width: 50%;
      }
    }

    .css-1xin95v {
      .MuiGrid-grid-xs-4 {
        max-width: 50%;
      }
    }

    .dropzone1 {
      color: #3f73f9;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 8px !important;
      padding: 20px;
      cursor: pointer;
      height: 130px;
      width: 343px;
      background: #eff4ff;
      border: 1px dashed #3f73f9;
      outline: none;
      border-radius: 5px;
    }

    .dropzoneText {
      padding-top: 5px;
      height: 32px;
      color: rgba(66, 69, 76, 0.50);
      font-size: 14px !important;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding-left: 10px;
      text-align: start !important;
      border-radius: 4px;
      background: #F0F1F3;
      box-shadow: 0px 0px 0.8372092843055725px 0px rgba(0, 0, 0, 0.04), 0px 0px 1.674418568611145px 0px rgba(0, 0, 0, 0.06), 0px 3.34883713722229px 6.69767432px444458px 0px rgba(0, 0, 0, 0.04);
    }

    .css-tmctnw-MuiGrid-root>.MuiGrid-item {
      padding-top: 16.75px !important;
    }

    .drag-text {
      margin-bottom: -10px !important;
    }

    .title-text {
      margin-bottom: 40px !important;
      margin-top: 40px !important;
      color: rgba(66, 69, 76, 0.60);
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      width: 343px;

      h2 {
        color: var(--colors-42454-c, #42454C);
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }

    .media-plan__drag-text {
      padding-bottom: 10px;
    }

    .css-1jaw3da {
      margin-right: -10px !important;
      font-size: 12px !important;
      font-style: normal !important;
      font-weight: 400 !important;
      line-height: normal !important;
    }

    .css-1b9ubul {
      margin-left: 15px !important;
    }

    .css-j204z7-MuiFormControlLabel-root {
      margin-right: -10px !important;
      font-size: 12px !important;
      font-style: normal !important;
      font-weight: 400 !important;
      line-height: normal !important;
    }

    .css-1i4iqqk-MuiFormGroup-root {
      margin-left: 15px !important;
    }
  }
}

@media screen and (max-width : 375px) {
  .media-plan__wrapper {
    .media-plan__drag-text {
      font-size: 10px;
      line-height: 9px;
      color: #3f73f9;
    }


    .days-grid {
      align-items: baseline;
      width: 287px;
      overflow-x: scroll;
    }

    .controls {
      gap: 12px !important;
      justify-content: flex-start;
      width: 287px;

      .controls_item {
        width: 287px;

        .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
          top: -11px !important;
          color: rgba(66, 69, 76, 0.50) !important;
          font-size: 14px !important;
          font-style: normal !important;
          font-weight: 400 !important;
          line-height: normal !important;
        }

        .css-p0rm37 {
          top: -11px !important;
          color: rgba(66, 69, 76, 0.50) !important;
          font-size: 14px !important;
          font-style: normal !important;
          font-weight: 400 !important;
          line-height: normal !important;
        }

        .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
          height: 27px;
          width: 287px;
          border-radius: 4px;
          background: #F0F1F3;
          box-shadow: 0px 0px 0.8372092843055725px 0px rgba(0, 0, 0, 0.04), 0px 0px 1.674418568611145px 0px rgba(0, 0, 0, 0.06), 0px 3.34883713722229px 6.69767427444458px 0px rgba(0, 0, 0, 0.04);

          input {
            font-size: 14px;
            padding: 0;
            padding-left: 10px;
            box-shadow: none;
          }

          fieldset {
            border: none;
          }
        }

        .css-1v4ccyo {
          height: 27px;
          width: 287px;
          border-radius: 4px;
          background: #F0F1F3;
          box-shadow: 0px 0px 0.8372092843055725px 0px rgba(0, 0, 0, 0.04), 0px 0px 1.674418568611145px 0px rgba(0, 0, 0, 0.06), 0px 3.34883713722229px 6.69767427444458px 0px rgba(0, 0, 0, 0.04);

          input {
            font-size: 14px;
            padding: 0;
            padding-left: 10px;
            box-shadow: none;
          }

          fieldset {
            border: none;
          }
        }

        &.timeTo {
          width: 136px;
        }

        &.timeFrom {
          width: 136px;
        }

        &.dateTo {
          width: 136px;
        }

        &.dateFrom {
          width: 136px;
        }
      }
    }

    .css-qazz20-MuiGrid-root {
      position: absolute;
      left: -20px;
    }

    .css-ws6o0d {
      position: absolute;
      left: -20px;
    }

    .dropzone1 {
      color: #3f73f9;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 8px !important;
      padding: 20px;
      cursor: pointer;
      height: 80px;
      width: 100%;
      background: #eff4ff;
      border: 1px dashed #3f73f9;
      outline: none;
      border-radius: 5px;
    }

    .dropzoneText {
      padding-top: 5px;
      height: 27px;
      color: rgba(66, 69, 76, 0.50);
      font-size: 14px !important;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding-left: 10px;
      text-align: start !important;
      border-radius: 4px;
      background: #F0F1F3;
      box-shadow: 0px 0px 0.8372092843055725px 0px rgba(0, 0, 0, 0.04), 0px 0px 1.674418568611145px 0px rgba(0, 0, 0, 0.06), 0px 3.34883713722229px 6.69767427444458px 0px rgba(0, 0, 0, 0.04);
    }

    .css-tmctnw-MuiGrid-root>.MuiGrid-item {
      padding-top: 16.75px !important;
    }

    .drag-text {
      margin-bottom: -10px !important;
    }

    .title-text {
      margin-bottom: 40px !important;
      margin-top: 40px !important;
      color: rgba(66, 69, 76, 0.60);
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      width: 287px;

      h2 {
        color: var(--colors-42454-c, #42454C);
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }

    .media-plan__drag-text {
      padding-bottom: 10px;
    }

    .css-1jaw3da {
      margin-right: -15px !important;
      font-size: 12px !important;
      font-style: normal !important;
      font-weight: 400 !important;
      line-height: normal !important;
    }

    .css-1b9ubul {
      margin-left: 15px !important;
    }

    .css-j204z7-MuiFormControlLabel-root {
      margin-right: -15px !important;
      font-size: 12px !important;
      font-style: normal !important;
      font-weight: 400 !important;
      line-height: normal !important;
    }

    .css-1i4iqqk-MuiFormGroup-root {
      margin-left: 15px !important;
    }
  }
}

@media screen and (min-width:768px) and (max-width : 1000px) {
  .css-13xfq8m-MuiTabPanel-root:has(.media-plan__wrapper) {
    padding: 24px 24px 24px 4px;
    margin: 0 !important;
  }

  .css-19kzrtu:has(.media-plan__wrapper) {
    margin: 0 !important;
  }

  .media-plan__wrapper {
    .toggle {
      margin-left: 1px;
      padding-left: 15px;
    }

    .dropzone1 {
      height: 101px;
      border-radius: 16px;
      width: 294px;
    }

    .days-grid {
      overflow-x: scroll;
      width: 630px;
      align-items: baseline;
    }

    .controls {
      width: 625px;
      gap: 15px;

      .controls_item {
        width: 305px;

        .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
          top: -7px !important;
          color: rgba(66, 69, 76, 0.50) !important;
          font-size: 14px !important;
          font-style: normal !important;
          font-weight: 400 !important;
          line-height: normal !important;
        }

        .css-p0rm37 {
          top: -7px !important;
          color: rgba(66, 69, 76, 0.50) !important;
          font-size: 14px !important;
          font-style: normal !important;
          font-weight: 400 !important;
          line-height: normal !important;
        }

        .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
          height: 32px;
          width: 305px;
          border-radius: 4px;
          background: #F0F1F3;
          box-shadow: 0px 0px 0.8372092843055725px 0px rgba(0, 0, 0, 0.04), 0px 0px 1.674418568611145px 0px rgba(0, 0, 0, 0.06), 0px 3.34883713722229px 6.69767432px444458px 0px rgba(0, 0, 0, 0.04);

          input {
            font-size: 14px;
            padding: 0;
            padding-left: 10px;
            box-shadow: none;
          }

          fieldset {
            border: none;
          }
        }

        .css-1v4ccyo {
          height: 32px;
          width: 305px;
          border-radius: 4px;
          background: #F0F1F3;
          box-shadow: 0px 0px 0.8372092843055725px 0px rgba(0, 0, 0, 0.04), 0px 0px 1.674418568611145px 0px rgba(0, 0, 0, 0.06), 0px 3.34883713722229px 6.69767432px444458px 0px rgba(0, 0, 0, 0.04);

          input {
            font-size: 14px;
            padding: 0;
            padding-left: 10px;
            box-shadow: none;
          }

          fieldset {
            border: none;
          }
        }

        &.timeTo {
          width: 144px;
        }

        &.timeFrom {
          width: 144px;
        }

        &.dateTo {
          width: 144px;
        }

        &.dateFrom {
          width: 144px;
        }
      }
    }
    .MuiGrid-grid-xs-4 {
      max-width: 100%;
    }

    .css-qazz20-MuiGrid-root {
      max-width: 50%;
    }

    .css-ws6o0d {
      max-width: 50%;
    }
  }
}